import { useEffect, useState } from "react"
import { getUserPreferences } from "state-mngt/selectors/user-selectors"
import { Measurement } from "types"
import { useAppSelector } from "./reduxTypes"

const roundToTenth = () => (x: number) => Math.round(x * 10) / 10
const pmMassToCount = () => (value: number) => Math.round((value / 0.5726) * 10) / 10
const pmCountToMass = () => (value: number) => Math.round((value * 0.5726) * 10) / 10
const vocPpbToMass = () => (value: number) => Math.round((value * 2.35849) * 10) / 10
const vocMassToPpb = () => (value: number) => Math.round((value / 2.35849) * 10) / 10
const picocuriesToBecquerel = () => (value: number) => Math.round((value * 37) * 10) / 10
const becquerelsToPicocuries = () => (value: number) => Math.round((value / 37) * 10) / 10

function useConvertParticulateUnit(measurement: Measurement, unit) {
  const [convertedUnit, setConvertedUnit] = useState<string | null>(null)
  const [display, setDisplay] = useState(roundToTenth)
  const [format, setFormat] = useState(roundToTenth)
  const preferences = useAppSelector(getUserPreferences)

  useEffect(() => {
    if (!measurement) return
    if (!preferences) return

    const { pm_isMc, voc_isMc, radon_unit } = preferences

    const _convertedUnit = measurement === 'pm25' ?
      (pm_isMc ? 'ugm3' : 'count') :
      measurement === 'voc' ?
        (voc_isMc ? 'ugm3' : 'ppb') :
        radon_unit || 'picocuries'

    setConvertedUnit(_convertedUnit)

    if (measurement === 'pm25') {
      if (!pm_isMc) {
        setDisplay(pmMassToCount)
        setFormat(pmCountToMass)
      }
    }

    if (measurement === 'voc') {
      if (voc_isMc) {
        setDisplay(vocPpbToMass)
        setFormat(vocMassToPpb)
      }
    }

    if (measurement === 'radon') {
      if (radon_unit === 'picocuries') {
        if (unit === 'becquerels') {
          setDisplay(becquerelsToPicocuries)
          setFormat(picocuriesToBecquerel)
        }
      }
      if (radon_unit === 'becquerels') {
        if (unit === 'picocuries') {
          setDisplay(picocuriesToBecquerel)
          setFormat(becquerelsToPicocuries)
        }
      }
    }

  }, [
    measurement,
    JSON.stringify(preferences),
  ])

  return {
    convertedUnit: convertedUnit || unit,
    display,
    format,
  }
}

export default useConvertParticulateUnit
