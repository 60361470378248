import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import deepEqual from 'deep-equal'
import {
  selectAllTempRulesByAutomation,
  selectTempRule,
  setTemporaryRuleInput,
  useRulesStore,
} from './store'
import RuleTitle from './rule-title'
import Stack from 'ui/stack'
import Footer from './footer'
import useRuleState from './useRuleState'
import IndoorCondition from './indoor-condition'
import OutdoorConditions from './outdoor-condition'
import SelectableEquipmentList from './selectable-equipment-list'
import useEquipmentAutomation from 'utils/hooks/useEquipmentAutomation'
import InnerCard from './inner-card'
import { isIndoorConditionInput, isIndoorHumidityInput } from './util'
import useEcosenseDevicesInCurrentDwellingZone from 'utils/hooks/useEcosenseDevicesInCurrentDwellingZone'
import EcosenseCondition from './ecosense-condition'
import RuleHeader from './rule-header'
import { DeleteRuleDialog } from './automations-editor/delete-rule-dialog'

const RuleEquipmentWrapper = ({ ruleId }) => {
  const automation = useEquipmentAutomation()
  const allOutputs = automation?.outputs.filter(x => x.equipment) || []
  const tempRule = useRulesStore(selectTempRule(ruleId))

  const availableOutputs = allOutputs
    .filter(x => !tempRule.outputs.map(x => x.id).includes(x.id))
    .filter(x => !tempRule.outputs.flatMap(x => x.interlocked_blowers)?.includes(x.id))

  useEffect(() => {
    if (tempRule.pillar !== 'humidity') return

    const hasDehu = tempRule.outputs.find(x => x.equipment?.type.includes('dehumidifier'))
    const hasHu = tempRule.outputs.find(x => x.equipment?.type === 'humidifier')
    if (!hasDehu && !hasHu) return

    /**
     * set the rising property based on whether a dehumidifer
     * or a humidifier has been set
     */
    const rising = hasDehu && !hasHu
    const _input = tempRule.inputs.find(isIndoorHumidityInput)

    const updatedInput = Object.fromEntries(
      Object.entries(_input)
        .map(([key, value]) => {
          if (key === 'rising') return [key, rising]
          return [key, value]
        }))

    if (JSON.stringify(_input) !== JSON.stringify(updatedInput)) setTemporaryRuleInput(ruleId, _input.id, updatedInput)
  }, [JSON.stringify(tempRule.outputs)])

  const removeSelected = () => {
    // not used
  }

  const setSelected = () => {
    // not used
  }

  return (
    <SelectableEquipmentList
      allOutputs={allOutputs}
      availableOutputs={availableOutputs}
      setSelectedOutputs={setSelected}
      removeSelected={removeSelected}
      ruleId={ruleId}
    />
  )
}

const useValidate = ({ tempRule }) => {
  const allRules = useRulesStore(selectAllTempRulesByAutomation(tempRule.automationId))
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    const errors = {
      'Please select at least one equipment.': !tempRule.outputs.length,
      'Cannot use both a humidifier and dehumidifier in a single automation.': tempRule.outputs.find(x => x.type.includes('dehumidifier')) && tempRule.outputs.find(x => x.type === 'humidifier'),
    }

    setError(Object.keys(errors).find(x => errors[x]))
  }, [JSON.stringify(tempRule.outputs)])

  useEffect(() => {
    const dehuInput = tempRule.inputs.find(x => isIndoorHumidityInput(x) && x.rising)
    const huInput = tempRule.inputs.find(x => isIndoorHumidityInput(x) && !x.rising)

    const humiditySetPointConflict = (dehuInput && huInput) && (
      dehuInput?.measurement === 'humidity' ?
        Math.abs(dehuInput?.start_set_point - huInput?.start_set_point) < 6 :
        Math.abs(dehuInput?.start_set_point - huInput?.start_set_point) < 2
    )

    const messaging = dehuInput?.measurement === 'humidity' ? '6%' : '2°'

    const errors = {
      'Please create at least one condition.': !tempRule.inputs.length,
      'Invalid custom value.': tempRule.inputs.some(x => x.start_set_point < 0 || Number.isNaN(x.start_set_point)),
      [`Humidifier and dehumidifer set points can't be within ${messaging} of one another`]: humiditySetPointConflict,
    }

    setError(Object.keys(errors).find(x => errors[x]))
  }, [JSON.stringify(tempRule.inputs)])

  useEffect(() => {
    if (!tempRule) return
    if (!allRules.length) return

    const current = {
      indoorInputs: tempRule.inputs.filter(isIndoorConditionInput).map(x => x.measurement).sort(),
      outputs: tempRule.outputs.map(x => x.id).sort(),
    }

    const hasDuplicate = Object.values(allRules).some(rule => {
      const existing = {
        indoorInputs: rule.inputs.filter(isIndoorConditionInput).map(x => x.measurement).sort(),
        outputs: rule.outputs.map(x => x.id).sort(),
      }

      return (rule.id !== tempRule.id) &&
        deepEqual(current, existing)
    })

    setError(hasDuplicate ? 'This automation is a duplicate of an existing automation. Please choose another equipment or indoor condition.' : '')
  }, [JSON.stringify(allRules), JSON.stringify(tempRule)])

  return error
}

const Rule = ({ ruleId, setEditing, setHeight }: { ruleId: number, setEditing: any, setHeight: any }) => {
  const tempRule = useRulesStore(selectTempRule(ruleId))
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let resizeObserver: ResizeObserver

    if (ref.current) {
      const handleResize = () => {
        if (!ref.current) return
        const { height } = ref.current.getBoundingClientRect()
        setHeight(height + 8)
      }

      resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(ref.current)
    }

    return () => {
      if (resizeObserver && ref.current) return resizeObserver.unobserve(ref.current)
    }
  }, [tempRule.id, ref.current])

  const {
    touched,
    loading,
    success,
    error,
    handleCancel,
    handleSave,
  } = useRuleState(ruleId)

  const validationError = useValidate({ tempRule })
  const ecosenseDevices = useEcosenseDevicesInCurrentDwellingZone()

  const _handleCancel = () => {
    handleCancel()
    setEditing(false)
  }

  const handleClose = () => setEditing(false)

  return (
    <div ref={ref}>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <RuleHeader
            override_on={tempRule.trigger.override_on}
            override_timeout={tempRule.trigger.override_timeout}
            currently_triggered={tempRule.trigger.currently_triggered}
            automationId={tempRule.automationId}
            ruleId={tempRule.id}
          />
          <InnerCard pt='10px'>
            <Stack spacing={2}>
              <RuleTitle>
                Activates
              </RuleTitle>
              <RuleEquipmentWrapper ruleId={ruleId} />
            </Stack>
          </InnerCard>
        </Stack>
        <IndoorCondition
          ruleId={ruleId}
        />
        {Boolean(tempRule?.pillar === 'ventilation' && Boolean(ecosenseDevices.length)) && ecosenseDevices.map(x => (
          <EcosenseCondition
            key={x.serial_number}
            ecosenseDevice={x}
            ruleId={ruleId}
          />
        ))}
        <OutdoorConditions
          ruleId={ruleId}
        />
        <Footer
          touched={tempRule.id < 0 || touched}
          loading={loading}
          success={success}
          error={error}
          validationError={validationError}
          handleCancel={_handleCancel}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      </Stack>
    </div>
  )
}

export {
  RuleHeader,
}

export default Rule
