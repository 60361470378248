import React, { ReactElement } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getPieChartOptions, getReportPieChartOptions } from 'ui/pie-chart/pie-chart-utils'
import { Box, createStyles, Grid, GridSize, makeStyles, Theme, Typography } from '@material-ui/core'
import { pieChartColors } from 'features/customer-drill-down/charts/device-chart/chart-utils'
import InfoTooltip from 'ui/info-tooltip'
import InfoIcon from '@material-ui/icons/Info'

export interface PieChartData {
  name: string;
  y: number;
}

window.Highcharts = Highcharts

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: 'none',
    },
    row: {
      textAlign: 'center',
      padding: '0 !important',
    },
    element: {
      position: 'relative',
      margin: 0,
      padding: '12px',
      '&:not(:last-child)': {
        borderRight: '1px solid rgba(0,0,0,0.12)'
      },
    },
    firstRow: {
      padding: '0 !important',
      fontWeight: 'bold',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  })
)

const CubeGrid = ({ contents }: { contents: Array<ReactElement[]> }) => {
  const classes = useStyles()

  const gridSize = 12 / contents[0].length as GridSize

  return (
    <div className={classes.row}>
      <Grid container spacing={3}>
        {contents.map((row, i) => (
          <Grid item xs={12} className={i > 0 ? classes.row : classes.firstRow}>
            <Grid container>
              {row.map(element => (
                <Grid item xs={gridSize} className={classes.element}>
                  {element}
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

const pill = {
  borderRadius: '99px',
  textAlign: 'center' as const,
  margin: 0,
  display: 'inline',
  padding: '4px 8px',
}

const good = {
  ...pill,
  border: '2px solid #39dd7b',
}

const fair = {
  ...pill,
  border: '2px solid #FFC700',
}

const poor = {
  ...pill,
  border: '2px solid #F96C6C',
}

const Tooltip = ({ title }) => {
  const _title = title.toLowerCase()

  const contentTitle = () => {
    if (_title.includes('filtration')) return 'PM2.5 '
    if (_title.includes('ventilation')) return 'tVOCs (total volatile organic compounds) '
    if (_title.includes('humidity')) return `Relative Humidity `
    if (_title.includes('radon')) return 'Radon '
  }

  const content = () => {
    if (_title.includes('filtration')) return 'particles are extremely small airborne particles with a diameter of 2.5 micrometers or less. They can have adverse effects on human health when inhaled.'
    if (_title.includes('ventilation')) return 'are organic chemicals that have low boiling points and can easily evaporate into the air. They are emitted from a wide range of sources, such as cooking or cleaning.'
    if (_title.includes('humidity')) return `is the percentage of water vapor in the air relative to the total amount that could be held at the current temperature.
 
Warm air can hold more moisture than cold air, which means air will have a higher RH level if the air is cooler, and lower RH if the air is warmer.`
    if (_title.includes('radon')) return 'is a gas is released from the ground and accumulates in indoor spaces, it can become a health concern, especially in poorly ventilated or tightly sealed buildings.'
  }

  const gridContents = () => {
    if (_title.includes('filtration')) return [[
      <p style={{ margin: 0 }}>Range</p>,
      <p style={{ margin: 0 }}>count/m³</p>,
      <p style={{ margin: 0 }}>ug/m³</p>,
    ], [
      <p style={{ ...good }}>Good</p>,
      <p style={{ margin: 0 }}>{`<20.5`}</p>,
      <p style={{ margin: 0 }}>{`11.7`}</p>,
    ], [
      <p style={{ ...fair }}>Fair</p>,
      <p style={{ margin: 0 }}>20.5-58</p>,
      <p style={{ margin: 0 }}>11.7-33.3</p>,
    ], [
      <p style={{ ...poor }}>Poor</p>,
      <p style={{ margin: 0 }}>{`>58`}</p>,
      <p style={{ margin: 0 }}>{`>33.3`}</p>,
    ]]
    if (_title.includes('ventilation')) return [[
      <p style={{ margin: 0 }}>Range</p>,
      <p style={{ margin: 0 }}>ppb</p>,
      <p style={{ margin: 0 }}>ug/m³</p>,
    ], [
      <p style={{ ...good }}>Good</p>,
      <p style={{ margin: 0 }}>{`<212`}</p>,
      <p style={{ margin: 0 }}>{`<500`}</p>,
    ], [
      <p style={{ ...fair }}>Fair</p>,
      <p style={{ margin: 0 }}>212-850</p>,
      <p style={{ margin: 0 }}>500-2000</p>,
    ], [
      <p style={{ ...poor }}>Poor</p>,
      <p style={{ margin: 0 }}>{`>850`}</p>,
      <p style={{ margin: 0 }}>{`>2000`}</p>,
    ]]
    if (_title.includes('humidity')) return [[
      <p style={{ margin: 0 }}>Range</p>,
      <p style={{ margin: 0 }}>%</p>,
    ], [
      <p style={{ ...poor }}>Too humid</p>,
      <p style={{ margin: 0 }}>70-100</p>,
    ], [
      <p style={{ ...fair }}>Humid</p>,
      <p style={{ margin: 0 }}>60-70</p>,
    ], [
      <p style={{ ...good }}>Good</p>,
      <p style={{ margin: 0 }}>30-60</p>,
    ], [
      <p style={{ ...fair }}>Dry</p>,
      <p style={{ margin: 0 }}>25-30</p>,
    ], [
      <p style={{ ...poor }}>Too dry</p>,
      <p style={{ margin: 0 }}>0-25</p>,
    ]]
    if (_title.includes('radon')) return [[
      <p style={{ margin: 0 }}>Range</p>,
      <p style={{ margin: 0 }}>pCi/L</p>,
      <p style={{ margin: 0 }}>Bq/m³</p>,
    ], [
      <p style={{ ...good }}>Good</p>,
      <p style={{ margin: 0 }}>{`<2.7`}</p>,
      <p style={{ margin: 0 }}>{`<100`}</p>,
    ], [
      <p style={{ ...fair }}>Fair</p>,
      <p style={{ margin: 0 }}>2.7-4</p>,
      <p style={{ margin: 0 }}>100-150</p>,
    ], [
      <p style={{ ...poor }}>Poor</p>,
      <p style={{ margin: 0 }}>{`>4`}</p>,
      <p style={{ margin: 0 }}>{`>150`}</p>,
    ]]
    return [[], [], []]
  }

  return (
    <InfoTooltip
      placement="right-start"
      arrow
      title={
        <>
          <Box>
            <span style={{ fontWeight: 'bold' }}>
              {contentTitle()}
            </span>
            {content()}
          </Box>
          <Box mt={1} p={1}>
            <CubeGrid contents={gridContents()} />
          </Box>
        </>
      }
    >
      <InfoIcon
        style={{
          marginBottom: '6px',
          marginLeft: '6px',
          fontSize: '20px',
        }}
      />
    </InfoTooltip>
  )
}

const getColor = (name: string) => {
  switch (name.toLowerCase()) {
    case 'good': return pieChartColors[0]
    case 'humid':
    case 'dry':
    case 'fair': return pieChartColors[1]
    case 'too humid':
    case 'too dry':
    case 'poor': return pieChartColors[2]
    default: return pieChartColors[3]
  }
}

const PieChart = (props: { data: PieChartData[], colors: string[], title: string, isReport: boolean }) => {
  const {
    data,
    colors,
    title,
    isReport,
  } = props

  const options = isReport ?
    getReportPieChartOptions(data, colors) :
    getPieChartOptions(data, colors, title)

  if (!data.length) return (
    <Box
      width='100%'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <p style={{
        color: '#878787',
      }}>No data</p>
    </Box>
  )

  return (
    <Box display='flex'>
      <Box width='144px'>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          allowChartUpdate={true}
        />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        flexGrow={1}
      >
        <Box
          display='flex'
          alignItems='center'
        >
          <p style={{
            marginTop: 0,
            marginBottom: '6px',
            fontSize: '14px',
            fontWeight: 'semi-bold',
          }}>{title}</p><Tooltip title={title} />
        </Box>
        {(data || []).map(x => (
          <Box
            display='flex'
            justifyContent='space-between'
          >
            <Box
              display='flex'
              alignItems='center'
            >
              <Box
                mr='4px'
                width='10px'
                height='10px'
                borderRadius='99px'
                bgcolor={getColor(x.name)}
              />
              <p style={{ margin: 0, fontSize: '12px' }}>{x.name}</p>
            </Box>
            <p style={{ margin: 0, fontSize: '12px' }} >{x.y}%</p>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default PieChart
