import { PieChartData } from 'ui/pie-chart/pie-chart'

/**
 * Generate pie chart options
 * @param data - PieChartData[]
 * @param colors  - string[]
 * @param title - string
 */
export const getPieChartOptions = (data: PieChartData[], colors: string[], title: string): Highcharts.Options => {
  return {
    title: {
      text: '',
    },
    chart: {
      plotShadow: false,
      type: 'pie',
      height: 148,
      reflow: true,
    },
    noData: {
      style: {
        color: 'transparent', // hide no data message
      },
    },
    credits: {
      enabled: false, // hide default message 'created by Highcharts'
    },
    plotOptions: {
      pie: {
        animation: false,
        slicedOffset: 0,
        allowPointSelect: true,
        colors,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        innerSize: 20,
        point: {
          events: {
            legendItemClick: (e) => {
              e.preventDefault()
            },
          },
        },
      },
    },
    tooltip: {
      pointFormat: '{series.name} <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    legend: {
      enabled: false,
    },
    // @ts-ignore
    series: [{
      name: '',
      colorByPoint: true,
      data,
      type: 'pie',
      animation: false,
    }],
  }
}

/**
 * Generate pie chart options for report
 * @param data - PieChartData[]
 * @param colors  - string[]
 */
export const getReportPieChartOptions = (data: PieChartData[], colors: string[]): Highcharts.Options => {
  return {
    title: {
      text: '',
    },
    chart: {
      plotShadow: false,
      type: 'pie',
      height: 80,
      width: 80,
      animation: false,
    },
    noData: {
      style: {
        color: 'transparent', // hide no data message
      },
    },
    credits: {
      enabled: false, // hide default message 'created by Highcharts'
    },
    plotOptions: {
      pie: {
        animation: false,
        slicedOffset: 0,
        allowPointSelect: false,
        colors,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
        innerSize: 10,
        point: {
          events: {
            legendItemClick: (e) => {
              e.preventDefault()
            },
          },
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },

      },
      series: {
        animation: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    //@ts-ignore
    series: [{
      name: '',
      colorByPoint: true,
      data,
      type: 'pie',
    }],
  }
}

