import { Box, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import Dot from './dot'
import EntityHeader from './entity-header'
import { selectAutomation, setAutomation, useAutomationsStore } from './store'
import httpService from 'state-mngt/services/data/http-service'
import { DeleteRuleDialog } from './automations-editor/delete-rule-dialog'

const RuleHeader = ({
    ruleId,
    automationId,
    override_timeout,
    override_on,
    currently_triggered,
}) => {
    const [loading, setLoading] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)

    const ruleIsRunning = Boolean((override_timeout && override_on) || currently_triggered)
    const automation = useAutomationsStore(selectAutomation(automationId))

    const handleDelete = async () => {
        setLoading(true)

        const updatedAutomation = {
            ...(automation || {}),
            rules: automation.rules
                .map(x => x.id === ruleId ?
                    { ...x, enabled: false } : x
                )
        }

        try {
            if (ruleId > 0) await httpService.post(`/automation/${automationId}`, updatedAutomation)
        } catch (e) {
            console.error(e)
            throw (e)
            // @ts-ignore
            // setError(e?.message || 'Server error')
        } finally {
            setLoading(false)
            setAutomation(automationId, updatedAutomation)
        }

    }

    return (
        <Box>
            <EntityHeader
                loading={loading}
                handleDelete={() => setDialogOpen(true)}
            >
                <Box
                    display='flex'
                    alignItems='center'
                    gridGap='8px'
                    justifyContent='space-between'
                >
                    Automation
                    <Box display='flex' gridGap='4px' alignItems='center'>
                        <Dot active={ruleIsRunning} />
                        {ruleIsRunning ?
                            <Box color='rgb(176 200 213)'>
                                <Typography variant='caption'>RUNNING</Typography>
                            </Box> :
                            null}
                    </Box>
                </Box>
            </EntityHeader>
            <DeleteRuleDialog
                onClickYes={handleDelete}
                onClickCancel={() => setDialogOpen(false)}
                loading={loading}
                isOpen={dialogOpen}
            />
        </Box>
    )
}

export default RuleHeader
