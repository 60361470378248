import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useMixPanel } from 'features/analytics'
import React, { useEffect } from 'react'
import { theme } from 'theme'
import ButtonSpinner from '../button-spinner'

const useStyles = makeStyles({
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogActions: {
    margin: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'stretch',
  },
})

export const DeleteRuleDialog = ({ onClickYes, onClickCancel, loading, isOpen }) => {
  const classes = useStyles()

  const { mixpanel } = useMixPanel()

  const _onClickYes = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_DeleteRuleDialog_yesCancelButton')
    }

    onClickYes()
  }

  const _onClickCancel = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_DeleteRuleDialog_backToEditButton')
    }

    onClickCancel()
  }

  useEffect(() => {
    if (mixpanel && isOpen) {
      mixpanel.track('pp_productTour_DeleteRuleDialog_open')
    }
  }, [mixpanel, isOpen])

  return (
    <Dialog
      aria-labelledby="delete-dialog-title"
      open={isOpen}>
      {/* <DialogTitle id="delete-dialog-title" style={{
        paddingBottom: '0',
      }} disableTypography>
        <Typography variant="h6" style={{
          color: theme.palette.grey[500],
        }}>Are you sure?</Typography>
      </DialogTitle> */}
      <DialogContent>
        <Typography
          variant="body1"
          color="textPrimary">
          Are you sure you want to delete this rule?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="secondary"
          disabled={loading}
          size='small'
          onClick={_onClickCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={loading}
          color="primary"
          size='small'
          onClick={_onClickYes}>
          {loading ? (
            <ButtonSpinner />
          ) : (
            <>
              Yes, I'm sure
            </>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
